<!-- 退件单上架 -->
<template>
    <div v-loading="loading" class="asnBillDetail">
        <!-- <div class="headerBtns">
            <el-button size="small" type="primary" round icon="iconfont icon-zuo" @click="$router.go(-1)"> {{ $t(`m.common.back`) }}
            </el-button>
        </div> -->
        <el-row>
            <el-form :model="formData" ref="formRef" label-width="120px" :rules="rules">
                <el-col :span="24">
                    <el-card>
                        <div class="flex justify-between">
                            <h3>{{ formData.asnMaster.returnNo }}</h3>
                            <div>
                                <el-button type="" size="small" @click="$router.go(-1)">取消</el-button>
                                <el-button class="ml10" type="primary" size="small" :disabled="!itemSelection.length" @click="onloadSunbmit">确认上架</el-button>
                            </div>
                        </div>
                        <el-divider></el-divider>
                        <div class="searchBar">
                            <!-- <el-tabs v-model="activeName" @tab-click="handleClick" style="width: 100%;">
                                <el-tab-pane label="基础信息" name="first"> -->
                                    <el-row>
                                        <el-col :span="6">
                                            <el-form-item label="入库仓:" label-width="60px">
                                                <span v-if="formData.asnMaster.wareId">{{ formatListDataByKey(formData.asnMaster.wareId, false, wares, 'wareName', 'wareId') }}</span>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="6">
                                            <el-form-item label="清点人:">
                                                <span>{{ formData.asnMaster.createUserName || '-' }}</span>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                <!-- </el-tab-pane>
                            </el-tabs> -->
                        </div>
                    </el-card>
                    <el-card>
                        <el-table ref="detailTable" height="calc(100vh - 380px)" stripe highlight-current-row :data="formData.asnDetails" style="width: 100%;"
                            @selection-change="handleItemListSelectionChange"
                            :header-cell-style="$parent.tableHeaderColor" border :show-summary="false">
                                <el-table-column type="selection" :key="0" width="55"></el-table-column>
                                <el-table-column prop="imageUrl" :key="4" :label="$t(`m.common.picture`)" width="120">
                                    <template slot-scope="scope">
                                        <el-image style="width: 80px; height: 80px" :src="scope.row.imageUrl" :preview-src-list="[scope.row.imageUrl]">
                                        </el-image>
                                    </template>
                                </el-table-column>
                                <el-table-column show-overflow-tooltip :key="9" prop="itemCode" label="SKU"></el-table-column>
                                <el-table-column show-overflow-tooltip :key="5" prop="itemName" :label="$t(`m.common.product_name`)"></el-table-column>
                                <el-table-column show-overflow-tooltip :key="7" prop="returnQuantity" label="应上架量" width="100"></el-table-column>
                                <el-table-column show-overflow-tooltip :key="8" prop="" label="待上 / 已上" width="120">
                                    <template slot-scope="scope">
                                        <div>{{ Number(scope.row.dsl||0) }}/{{ scope.row.ysl }}</div>
                                    </template>
                                </el-table-column>
                                <el-table-column  :key="12" prop="" label="库存属性" width="120">
                                    <template slot-scope="scope">
                                        <div class="flex">
                                            <div class="ml20">
                                                <div v-if="scope.row.numberKey">
                                                <el-button type="text" class="my-success" v-if="scope.row.quantity">良品</el-button>
                                                <el-button type="text" class="my-warning" v-else-if="scope.row.rejectQuantity">次品</el-button>
                                                <el-button type="text" class="my-danger" v-else-if="scope.row.scrapQuantity">报废品</el-button>
                                                </div>
                                                <div v-else>-</div>
                                            </div>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column show-overflow-tooltip :key="11" prop="" label="上架数量">
                                    <template slot-scope="scope">
                                        <!-- <el-form-item style="width: 100%;" v-if="scope.$index >= 0" label-width="20" :prop="'asnDetails.' + scope.$index + '.groupNum'"
                                            :rules="isCheck(scope.row) ? rules.groupNum : null">
                                            <el-input-number class="w100" style="width: 120px;" v-model="scope.row.groupNum" controls-position="right" size="small"
                                                :min="1" :max="scope.row.dsl" placeholder="上架数量" :precision="0">
                                            </el-input-number>
                                        </el-form-item> -->
                                        {{ scope.row.groupNum }}
                                    </template>
                                </el-table-column>
                                <el-table-column  :key="10" prop="" label="上架库位" width="440px">
                                    <template slot-scope="scope">
                                        <div class="flex">
                                            <el-form-item style="width: 90%;" v-if="scope.$index >= 0" label-width="0" :prop="'asnDetails.' + scope.$index + '.cellCode'"
                                                :rules="isCheck(scope.row) ? rules.cellCode : null">
                                                <el-select class="ml10 w100" filterable size="small" v-model="scope.row.cellCode" placeholder="上架库位" @change="(v) => cellCodeChange(v, scope.row)">
                                                    <el-option v-for="(iInfo,cellIndex) in getCellInfos(scope.row)" :key="cellIndex" :label="iInfo.cellCode" :value="iInfo.cellCode">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                    </el-card>
                </el-col>
            </el-form>
        </el-row>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { formatListDataByKey } from '@/utils/DateUtils.js'
import _ from 'lodash'


export default {
    name: 'returnDetal',
    data() {
        return {
            loading: false,
            activeName: 'first',
            activeName2: 'first',
            searchData: {
                keyWord: this.$route.params.returnNo,
                wareId: PF.getLocal('wareId'),
            },
            asnMaster: {},
            asnDetails: [],
            acceptRecords: [],
            exDetails: [],
            formData: {
                asnMaster: {},
                asnDetails: [],
            },
            carriers: [],
            rules: {
                groupNum: [{required: true, message: '请输入', trigger: 'blur'}],
                cellCode: [{required: true, message: '请选择', trigger: 'change'}],
            },
            formLabelWidth: '120px',
            cellInfos: [],
            wareId: PF.getLocal('wareId'),
            wareCode: PF.getLocal('wareCode'),
            itemSelection: [],
            wares: [],
            asnDetailsOld: []
        };
    },
    computed: {
        ...mapState(['abcLevels', 'qcStates', 'cellUseTypes', 'cellAbcLevels', 'cellPickTypes', 'asnStates', 'asnStatusTypes']),
        supplierId() {
            return this.form.asnMaster.supplierId;
        },
        isFile() {
            if (this.$route.params.fileUserId) {
                return true;
            } else {
                return false;
            }
        },
        getCellInfos() { //库位筛选
            return (row) => {
                // quantity rejectQuantity scrapQuantity -- 良品 
                if (row.numberKey == 'rejectQuantity') { //次品 
                    return _.filter(this.cellInfos||[], item => item.cellUseType == 5)
                }
                if (row.numberKey == 'scrapQuantity') { //报废品
                    return _.filter(this.cellInfos||[], item => item.cellUseType == 6)
                }
                return _.filter(this.cellInfos||[], item => item.cellUseType != 5 && item.cellUseType != 6)
            }
        },
        isCheck() {
            return (row) => {
                let obj = _.find(this.itemSelection||[], item => item.returnDetailId == row.returnDetailId && item.numberKey === row.numberKey)
                return !_.isEmpty(obj)
            }
        },
    },
    watch: {
    },
    methods: {
        formatListDataByKey,
        // 库位选择触发 获取库位类型
        cellCodeChange(v, row) {
            let obj = _.find(this.cellInfos, i => i.cellCode === v)
            if (v) {
                row.cellUseType = obj.cellUseType
            }
        },
        // 多选change事件
        handleItemListSelectionChange(val) {
            this.itemSelection = val;
        },
        // 确认上架
        onloadSunbmit() {
            IOT.showOverlay('保存中，请稍等...');
            this.$refs.formRef.validate((valid) => {
                if (valid) {
                    let { returnNo } = this.formData.asnMaster
                    let deepItemSelection = _.cloneDeep(this.itemSelection)
                    // 分组 合并对比sku和库存属性，将拆分的数据合并
                    let selectFilter = _.filter(this.asnDetailsOld, i => {
                        let obj = _.find(deepItemSelection, ii => ii.itemCode == i.itemCode && ii.numberKey == i.numberKey)
                        if (!_.isEmpty(obj)) {
                            return i
                        }
                    })
                    let returnDataDetailList = _.map(selectFilter, i => {
                        let obj = _.find(deepItemSelection, ii => ii.itemCode == i.itemCode && ii.numberKey == i.numberKey)
                        if (!_.isEmpty(obj)) {
                            i.cellCode = obj.cellCode
                        }
                        i.returnNo = returnNo
                        return i
                    })
                    IOT.getServerData('/return/masters/deliveryOfGoods', 'post', {
                        returnDataDetailList
                    }, (ret) => {
                        IOT.hideOverlay();
                        if (ret.code === 200) {
                            IOT.tips('操作成功！', 'success');
                            this.getList()
                            setTimeout(() => {
                                this.$router.push('/work/billInManage/returnBill')
                            }, 300)
                        } else {
                            IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                        }
                    });
                } else { 
                    IOT.hideOverlay();
                    console.error('error submit!!');
                    return false;
                }
            });
        },
        // 获取列表
        getList() {
            this.loading = true;
            let url = '/return/masters/deliveryDetail';
            IOT.getServerData(url, 'post', this.searchData, (ret) => {
                this.loading = false;
                if (ret.code === 200) {
                    let childList = []
                    this.formData.asnDetails = _.map(ret.data?.returnDetailList||[], item => {
                        item.quantity = item.quantity || undefined //良品
                        item.rejectQuantity = item.rejectQuantity || undefined //次品
                        item.scrapQuantity = item.scrapQuantity || undefined //报废品
                        item.dsl = _.toNumber((item.returnQuantity||0) - ((item.quantity||0) + (item.rejectQuantity||0) + (item.scrapQuantity||0))) || 0
                        item.ysl = _.toNumber((item.quantity||0) + (item.rejectQuantity||0) + (item.scrapQuantity||0)) || 0
                        // 分解三条数据 库存属性根据对应的良品、次品，显示。然后再根据固定的库存属性获取上架库存
                        let quantits = ['quantity', 'rejectQuantity', 'scrapQuantity']
                        _.forEach(quantits, iii => {
                            if (item[iii]) {
                                childList.push({...item, numberKey: iii})
                            } 
                        })
                        return item
                    })
                    if (childList.length) {
                        let asnDetails = _.map(childList||[], item => {
                            let quantits = ['quantity', 'rejectQuantity', 'scrapQuantity']
                            _.forEach(quantits, iii => {
                                if (item.numberKey != iii) {
                                    item[iii] = undefined
                                }
                            })
                            item.returnQuantity = item[item.numberKey]
                            item.groupNum = item[item.numberKey]
                            item.dsl = item[item.numberKey]
                            item.ysl = 0
                            if (item.jsonNum) {
                                let objDatas = JSON.parse(item.jsonNum)
                                if (!_.isEmpty(objDatas) && objDatas[item.numberKey]) {
                                    item.arrayNum = objDatas[item.numberKey]
                                }
                            }
                            return item
                        })
                        this.formData.asnDetails = _.filter(asnDetails, item => !item.arrayNum)
                    }
                    this.asnDetailsOld = _.cloneDeep(this.formData.asnDetails)
                    this.formData.asnMaster = ret.data
                    // 展示批量上架时sku和库存属性相同的sku
                    this.formData.asnDetails = this.mergeObjects(this.asnDetailsOld)
                    console.log(this.formData.asnDetails, 'this.formData.asnDetails', this.asnDetailsOld);
                } else {
                    IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                }
            }, true);
        },
        // 合并sku和库存属性相同的sku 并累加对应数量
        mergeObjects(array) {
            return _(array).groupBy(obj => `${obj.itemCode}_${obj.numberKey}`)
                .map(group => ({
                    ..._.merge({}, ...group),
                    returnQuantity: _.sumBy(group, 'returnQuantity'),
                    dsl: _.sumBy(group, 'dsl'),
                    ysl: _.sumBy(group, 'ysl'),
                    groupNum: _.sumBy(group, 'groupNum'),
                })).value();
        },
        // 合计产品信息
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '总计';
                    return;
                }
                const values = data.map(item => Number(item[column.property]));
                if (!values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + curr;
                        } else {
                            return prev;
                        }
                    }, 0);
                } else {
                    sums[index] = '';
                }
            });
            return sums;
        },
        // 入库记录
        getAsnRecordData() {
            this.acceptRecords = [];
            IOT.getServerData(
                '/accept/records/list',
                'post',
                {
                    asnBillNo: this.searchDataThird.billNo,
                    pageNum: this.searchDataThird.pageNum,
                    pageSize: this.searchDataThird.pageSize,
                },
                (ret) => {
                    if (ret.code === 200) {
                        this.acceptRecords = _.map(ret.rows, item => {
                            return item
                        });
                        this.searchDataThird.total = ret.total;
                        this.searchDataThird.pageNum = ret.pageNumber;
                    } else {
                        IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                    }
                }
            );
        },
        handleClick(tab, event) {
            console.log(tab, event);
        },
        handleCurrentChange() {
            // this.pageNum = val;
            // this.getItemData();
        },
        getAsnMasterData() {
            this.loading = true;
            let url = '';
            if (this.isFile) {
                url = '/asn/master/files/list';
            } else {
                url = '/asn/masters/findByBillNoList';
            }
            IOT.getServerData(
                url,
                'post',
                {
                    billNo: this.searchData.billNo,
                },
                (ret) => {
                    this.loading = false;
                    if (ret.code === 200) {
                        let list = ret.rows;
                        this.asnMaster = list;
                    } else {
                        IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                    }
                }
            );
        },
        // 物流商数据源
        getCarrierData() {
            IOT.getServerData('/carriers/list', 'post', {}, (ret) => {
                if (ret.code === 200) {
                    this.carriers = ret.rows;
                } else {
                    IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
                }
            });
        },
        resetForm(formName) {
            // 重置
            this.$refs[formName].resetFields();
        },
        // 获取上架库位sku数据
        getAcceptCell() {
            let param = {
                wareId: this.wareId,
                wareCode: this.wareCode,
            };
            IOT.getServerData('/cell/infos/lableList', 'post', param, (ret) => {
            IOT.hideOverlay();
            if (ret.code === 200) {
                this.cellInfos = _.flatten(_.map(_.filter(ret.rows, i => i.labelCode !== 0), ii => ii.dtoList)); // 过滤调验收位
            } else {
                IOT.tips(ret.message || this.$t(`m.common.server_request_failed`), 'error');
            }
            });
        },
    },
    created() {
        // this.getCarrierData(); //物流商
        // this.getAsnMasterData(); //form
        // this.getAsnRecordData(); //入库记录
        this.getAcceptCell()
        this.getList();
        PF.getWares((rows) => {
            this.wares = rows;
        })
    },
    mounted() { 
    },
};
</script>

<style>
.custom-tooltip {
    max-width: 400px !important;
    /* 设置 Tooltip 的最大宽度 */
}
</style>

<style scoped lang="less">
.asnBillDetail {}

:deep(.el-form-item) {
    margin-bottom: 0;
}
</style>